import styled from 'styled-components';

import { ApiFilestackImageData } from 'api/types/ApiFilestackImageData';
import { Box } from 'components/Box/Box';
import { Hero } from 'components/Hero/Hero';
import { H1 } from 'components/Text/H1';
import { RichText } from 'components/Text/RichText';
import { useSearchHero } from 'modules/search/hooks/useSearchHero';
import { SearchType } from 'modules/search/types/SearchType';
import {
  colors,
  cssBreakpoints,
  fontSizesByType,
  gutterWidth,
  maxWidth,
} from 'theme/theme';

import { SearchHeroInputs } from './SearchHeroInputs';

const Title = styled.div`
  line-height: 1.3;
  font-size: ${fontSizesByType.h3[0]}px;

  @media all and (min-width: ${cssBreakpoints.smUp}) {
    font-size: ${fontSizesByType.h3[2]}px;
  }
`;

type Props = {
  title?: string;
  image: ApiFilestackImageData | undefined;
  imageMobile: ApiFilestackImageData | undefined;
  defaultSearchType: SearchType;
};

export function SearchHero({
  title,
  image,
  imageMobile,
  defaultSearchType,
}: Props) {
  const {
    initialQuery,
    search,
    searchLocation,
    changeSearchLocation,
    redirectParam,
    showLogin,
    clickLogin,
    clickSignup,
  } = useSearchHero({ searchFrom: 'Search Hero' });

  return (
    <Hero
      pb={[36, null, null, 64]}
      pt={[36, null, null, 80]}
      minHeight={['470px', null, null, 'calc(100vh - 300px)']}
      heroImage={image}
      mobileHeroImage={imageMobile}
      eagerLoadImage
      data-qa-id="search-hero"
    >
      <Box
        data-qa-id="search-hero-menu"
        width="100%"
        maxWidth={[400, null, null, maxWidth[3]]}
        px={[0, null, null, gutterWidth]}
        m="0 auto"
      >
        <Box
          width={[1, null, null, 1 / 2]}
          px={[20, null, null, 60]}
          py={[20, null, null, 40]}
          borderRadius={12}
          boxShadow="0 0 6px rgba(0,0,0,0.3)"
          bg="white"
          color={colors.mediumContentGrey}
        >
          <Box mb={20}>
            <RichText>
              <H1>
                <Title
                  dangerouslySetInnerHTML={{
                    __html: title?.replace(/<(\/?)(p|h1)>/g, '<$1span>') || '',
                  }}
                />
              </H1>
            </RichText>
          </Box>

          <SearchHeroInputs
            initialQuery={initialQuery}
            defaultSearchType={defaultSearchType}
            onSearch={search}
            searchLocation={searchLocation}
            onChangeSearchLocation={changeSearchLocation}
            redirectParam={redirectParam}
            clickLogin={clickLogin}
            showLogin={showLogin}
            clickSignup={clickSignup}
          />
        </Box>
      </Box>
    </Hero>
  );
}
